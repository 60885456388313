import { AdminContainer } from "../../components/layouts";
import { Card, Typography } from "@material-ui/core";
import React from "react";
import { useUser } from "../../hooks/user";
import config from "../../config";

import {
  Chat,
  Channel,
  ChannelList,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
  useCreateChatClient,
  InfiniteScroll,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";

const USER_ID = "admin-boom";
const filters = {
  members: { $in: [USER_ID] },
  type: "messaging",
};
const options = { presence: true, state: true };
const sort = { last_message_at: -1 };

const ChatsWrapper = () => {
  const { user } = useUser();
  const streamChatToken = user?.streamChatToken;
  if (!streamChatToken) return <div>Loading..., no stream chat token yet</div>;
  if (!config.streamApiKey) return <div>no stream api key</div>;

  return <ChatsPage />;
};

const ChatsPage = () => {
  const { user } = useUser();
  const streamChatToken = user?.streamChatToken;

  const client = useCreateChatClient({
    apiKey: config.streamApiKey,
    tokenOrProvider: streamChatToken,
    userData: { id: USER_ID },
  });

  if (!client) return <div>Loading...</div>;

  return (
    <AdminContainer variant="list">
      <Typography variant="h4">Chats</Typography>
      <Card>
        <Chat client={client}>
          <div className="w-full flex max-h-[calc(100vh-320px)]">
            <div className="overflow-y-auto max-w-[340px]">
              <ChannelList
                additionalChannelSearchProps={{
                  searchFunction: (props, event) => {
                    const { setResults, setSearching, setQuery } = props;
                    const value = event.target.value;

                    if (value) filters.name = { $autocomplete: value };

                    setSearching(true);
                    setQuery(value);

                    client
                      .queryChannels(filters)
                      .then((channels) => {
                        setResults(channels);
                        setSearching(false);
                      })
                      .catch(() => {
                        setResults([]);
                        setSearching(false);
                      });
                  },
                }}
                sort={sort}
                filters={filters}
                searchForChannels
                showChannelSearch
                allowNewMessagesFromUnfilteredChannels
                Paginator={InfiniteScroll}
              />
            </div>
            <div className="grow">
              <Channel>
                <div className="flex grow max-h-[calc(100vh-320px)]">
                  <Window>
                    <ChannelHeader />
                    <MessageList />
                    <MessageInput />
                  </Window>
                </div>
              </Channel>
            </div>
          </div>
        </Chat>
      </Card>
    </AdminContainer>
  );
};

export { ChatsWrapper as ChatsPage };
