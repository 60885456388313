import React from "react";
import { Redirect } from "react-router";
import { locationAsNext } from "../lithic/followNext";
import { useUser } from "../hooks/user";

function redirectUnless(to, appendNext, test) {
  return (Wrapped) => {
    return (props) => {
      const userCtx = useUser();
      if (userCtx.userLoading) {
        return null;
      }
      let realTo = to;
      if (appendNext) {
        realTo = locationAsNext(window.location, to);
      }
      return test(userCtx) ? <Wrapped {...props} /> : <Redirect to={realTo} />;
    };
  };
}

export const redirectIfAuthed = redirectUnless(
  "/dashboard",
  false,
  ({ userUnauthed }) => userUnauthed
);

export const redirectIfUnauthed = redirectUnless(
  "/sign-in",
  true,
  ({ userAuthed }) => userAuthed
);
