import { AdminContainer, AlignedListContainer } from "../components/layouts";
import { GutterlessListItem as ListItem } from "../components/lists";
import Time from "../lithic/Moment";

import DetailSection from "../components/DetailSection";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../lithic/Money";
import React from "react";
import api from "../api";
import SimpleTable from "../components/SimpleTable";
import { useParams } from "react-router";

export default function StatementDetailPage() {
  const { id } = useParams();
  const { statement_id } = useParams();

  const [statementActivities, setStatementActivities] = React.useState([]);
  const [statement, setStatement] = React.useState({});

  React.useEffect(() => {
    Promise.all([
      api.getPartnerStatement({ id, statement_id }).then(api.pickData).then(setStatement),
      api
        .getPartnerStatementActivities({ id, statement_id })
        .then(api.pick("data.items"))
        .then(setStatementActivities),
    ]);
  }, [id, statement_id]);

  return (
    <AdminContainer variant="detail">
      <AlignedListContainer maxWidth="sm">
        <ListItem>
          <ListItemText>Statement Id: {statement.id}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Total: <Money key={5} value={statement.total} />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Status: {statement.status === "finished" ? "Paid" : "Unpaid"}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Month:{" "}
            <Time d="(--)" formatExact={"MMMM YYYY"} timezone="UTC">
              {statement.period?.start}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Due Date:{" "}
            <Time d="(--)" formatExact={"MMM DD, YYYY"} timezone="UTC">
              {statement.period?.end}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Type: {statement.payType === "pay_in" ? "Charge" : "Payout"}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Payments Method:{" "}
            {statement.bankAccount?.name && statement.bankAccount.last4
              ? `${statement.bankAccount.name} **** ${statement.bankAccount.last4}`
              : "Account for payouts is not selected"}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Paid On:{" "}
            <Time d="(--)" formatExact={"MMM DD, YYYY"}>
              {statement.sendingAt || statement.paidAt
                ? statement.payType === "pay_in"
                  ? statement.paidAt
                  : statement.sendingAt
                : undefined}
            </Time>
          </ListItemText>
        </ListItem>
      </AlignedListContainer>

      <DetailSection title={`Activities`}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={[
            "Product",
            "Item",
            "Name",
            "Email",
            "Property Name",
            "Property Address",
            "Unit",
            "Amount",
          ]}
          rows={statementActivities}
          keyRowAttr="id"
          toCells={(row) => [
            <ListItem key={1}> {row.metadata.product} </ListItem>,
            <ListItem key={2}>{row.description}</ListItem>,
            <ListItem key={3}> {row.metadata.name} </ListItem>,
            <ListItem key={4}> {row.metadata.email} </ListItem>,
            <ListItem key={5}> {row.metadata.propertyName} </ListItem>,
            <ListItem key={6}> {row.metadata.oneLineAddress} </ListItem>,
            <ListItem key={7}> {row.metadata.unit} </ListItem>,
            <Money key={8} value={row.amount} />,
          ]}
        />
      </DetailSection>
    </AdminContainer>
  );
}
