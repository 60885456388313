import "react-toastify/dist/ReactToastify.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { redirectIfAuthed, redirectIfUnauthed } from "./hocs/authRedirects";

import AdminAppBar from "./components/AdminAppBar";
import AffiliateDetailPage from "./pages/AffiliateDetailPage";
import AffiliateListPage from "./pages/AffiliateListPage";
import BankAccountDetailPage from "./pages/BankAccountDetailPage";
import CardDetailPage from "./pages/CardDetailPage";
import CreditAccountEnrollmentDetailPage from "./pages/CreditAccountEnrollmentDetailPage";
import CreditAccountEnrollmentsListPage from "./pages/CreditAccountEnrollmentsListPage";
import CssBaseline from "@material-ui/core/CssBaseline";
import CustomerDetailPage from "./pages/CustomerDetailPage/CustomerDetailPage";
import CustomersListPage from "./pages/CustomersListPage";
import DashboardPage from "./pages/DashboardPage";
import DayjsUtils from "@date-io/dayjs";
import EnrollmentVerificationDetailPage from "./pages/EnrollmentVerificationDetailPage/EnrollmentVerificationDetailPage";
import EnrollmentVerificationsListPage from "./pages/EnrollmentVerificationsListPage";
import FurnishmentDetailPage from "./pages/FurnishmentDetailPage";
import FurnishmentsListPage from "./pages/FurnishmentsListPage";
import InvoiceDetailPage from "./pages/InvoiceDetailPage";
import InvoicesListPage from "./pages/InvoicesListPage";
import ApplicantDetailPage from "./pages/ApplicantDetailPage/ApplicantDetailPage";
import ApplicationDetailPage from "./pages/ApplicationDetailPage/ApplicationDetailPage";
import KybVerificationDetailPage from "./pages/KybVerificationDetailPage/KybVerificationDetailPage";
import KybVerificationsListPage from "./pages/KybVerificationsListPage";
import { LoadingProvider } from "./hooks/loading";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import PartnerDetailPage from "./pages/PartnerDetailPage/PartnerDetailPage";
import PartnerdListPage from "./pages/PartnerListPage";
import PaymentDetailPage from "./pages/PaymentDetailPage";
import PaymentVerificationDetailPage from "./pages/PaymentVerificationPage/PaymentVerificationDetailPage";
import PaymentsListPage from "./pages/PaymentsListPage";
import PayoutDetailPage from "./pages/PayoutDetailPage";
import PayoutsListPage from "./pages/PayoutsListPage";
import ApplicationListPage from "./pages/ApplicationListPage/ApplicationListPage";
import React from "react";
import { Redirect } from "react-router";
import RentalVerificationDetailPage from "./pages/RentalVerificationDetailPage";
import ReportingAccountEnrollmentDetailPage from "./pages/ReportingAccountEnrollmentDetailPage/ReportingAccountEnrollmentDetailPage";
import ReportingAccountEnrollmentsListPage from "./pages/ReportingAccountEnrollmentsListPage";
import RootPage from "./pages/RootPage";
import SignInPage from "./pages/SignInPage";
import { ThemeProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import TradelineDetailPage from "./pages/TradelineDetailPage/TradelineDetailPage";
import { UserProvider } from "./hooks/user";
import { VerifiedEnrollmentsListPage } from "./pages/VerifiedEnrollmentsListPage";
import applyHocs from "./lithic/applyHocs";
import theme from "./theme";
import withParamsKey from "./lithic/withParamsKey";
import { VerificationIdPage } from "./pages/VerificationIdPage";
import { ChatsPage } from "./pages/ChatsPage";
import StatementDetailPage from "./pages/StatementDetailPage";

const NavSwitch = () => {
  return (
    <Switch>
      <Route path="/" exact render={() => null} />
      <Route component={AdminAppBar} />
    </Switch>
  );
};

export default function App() {
  return (
    <Router>
      <UserProvider>
        <LoadingProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <main>
                <NavSwitch />
                <PageSwitch />
              </main>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </LoadingProvider>
      </UserProvider>
      <ToastContainer hideProgressBar={true} />
    </Router>
  );
}

function PageSwitch() {
  return (
    <Switch>
      <Route exact path="/" component={RootPage} />
      <Route exact path="/sign-in" component={applyHocs(redirectIfAuthed, SignInPage)} />
      <Route
        exact
        path="/dashboard"
        component={applyHocs(redirectIfUnauthed, DashboardPage)}
      />
      <Route
        exact
        path="/affiliates"
        component={applyHocs(redirectIfUnauthed, AffiliateListPage)}
      />
      <Route
        exact
        path="/affiliates/:id"
        component={applyHocs(redirectIfUnauthed, AffiliateDetailPage)}
      />
      <Route
        exact
        path="/partners"
        component={applyHocs(redirectIfUnauthed, PartnerdListPage)}
      />
      <Route
        exact
        path="/partners/:id"
        component={applyHocs(redirectIfUnauthed, PartnerDetailPage)}
      />
      <Route
        exact
        path="/partners/:id/statement/:statement_id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          withParamsKey("statement_id"),
          StatementDetailPage
        )}
      />
      <Route
        exact
        path="/customers"
        component={applyHocs(redirectIfUnauthed, CustomersListPage)}
      />
      <Route
        exact
        path="/customers/:id"
        component={applyHocs(redirectIfUnauthed, withParamsKey("id"), CustomerDetailPage)}
      />
      <Route
        exact
        path="/payments"
        component={applyHocs(redirectIfUnauthed, PaymentsListPage)}
      />
      <Route
        exact
        path="/payments/:id"
        component={applyHocs(redirectIfUnauthed, withParamsKey("id"), PaymentDetailPage)}
      />
      <Route
        exact
        path="/invoices"
        component={applyHocs(redirectIfUnauthed, InvoicesListPage)}
      />
      <Route
        exact
        path="/invoices/:id"
        component={applyHocs(redirectIfUnauthed, withParamsKey("id"), InvoiceDetailPage)}
      />
      <Route
        exact
        path="/payouts"
        component={applyHocs(redirectIfUnauthed, PayoutsListPage)}
      />
      <Route
        exact
        path="/payouts/:id"
        component={applyHocs(redirectIfUnauthed, withParamsKey("id"), PayoutDetailPage)}
      />
      <Route
        exact
        path="/credit-account-enrollments"
        component={applyHocs(redirectIfUnauthed, CreditAccountEnrollmentsListPage)}
      />
      <Route
        exact
        path="/credit-account-enrollments/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          CreditAccountEnrollmentDetailPage
        )}
      />
      <Route
        exact
        path="/reporting-account-enrollments"
        component={applyHocs(redirectIfUnauthed, ReportingAccountEnrollmentsListPage)}
      />
      <Route
        exact
        path="/reporting-account-enrollments/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          ReportingAccountEnrollmentDetailPage
        )}
      />
      <Route
        exact
        path="/tradelines/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          TradelineDetailPage
        )}
      />
      <Route
        exact
        path="/reporting-account-enrollments/:id/payment-verifications"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          PaymentVerificationDetailPage
        )}
      />
      <Route
        exact
        path="/enrollment-verifications"
        component={applyHocs(redirectIfUnauthed, EnrollmentVerificationsListPage)}
      />
      <Route
        exact
        path="/rental-verifications/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          RentalVerificationDetailPage
        )}
      />
      <Route
        exact
        path="/bank-accounts/:id"
        component={applyHocs(redirectIfUnauthed, BankAccountDetailPage)}
      />
      <Route
        exact
        path="/cards/:id"
        component={applyHocs(redirectIfUnauthed, CardDetailPage)}
      />
      <Route
        exact
        path="/furnishments"
        component={applyHocs(redirectIfUnauthed, FurnishmentsListPage)}
      />
      <Route
        exact
        path="/furnishments/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          FurnishmentDetailPage
        )}
      />
      <Route
        exact
        path="/enrollment-verifications/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          EnrollmentVerificationDetailPage
        )}
      />
      <Route
        exact
        path="/payment-verifications"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          VerifiedEnrollmentsListPage
        )}
      />
      <Route
        exact
        path="/kyb-verifications"
        component={applyHocs(redirectIfUnauthed, KybVerificationsListPage)}
      />
      <Route
        exact
        path="/kyb-verifications/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          KybVerificationDetailPage
        )}
      />
      <Route
        exact
        path="/applications"
        component={applyHocs(redirectIfUnauthed, ApplicationListPage)}
      />
      <Route
        exact
        path="/applications/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          ApplicationDetailPage
        )}
      />

      <Route exact path="/chats" component={applyHocs(redirectIfUnauthed, ChatsPage)} />

      <Route
        exact
        path="/applicants/:id"
        component={applyHocs(
          redirectIfUnauthed,
          withParamsKey("id"),
          ApplicantDetailPage
        )}
      />
      <Route
        path="/applicants/:id/verifications/:verificationId"
        render={() => <VerificationIdPage />}
      />
      <Route path="/" render={() => <Redirect to="/" />} />
    </Switch>
  );
}
