import { AccountCircle } from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Link from "./Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import clsx from "clsx";
import config from "../config";
import logo from "../assets/images/logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import signOut from "../modules/signOut";
import { useUser } from "../hooks/user";
import { withRouter } from "react-router";

const height = 60;

const useStyles = makeStyles((theme) => ({
  appBar: { zIndex: theme.zIndex.drawer + 1, height: height, boxShadow: "none" },
  commitRef: {
    color: theme.palette.muted.main,
  },
  toolbar: theme.mixins.toolbar,
  title: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
  },
  titleUnauthed: {
    textAlign: "center",
  },
  logo: {
    height: 45,
    marginRight: theme.spacing(2),
  },
  signInButton: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.light.main,
  },
  userIcon: {
    marginLeft: theme.spacing(2),
  },
}));

export default function AdminAppBar() {
  const classes = useStyles();
  const { user, userAuthed, userUnauthed } = useUser();
  return (
    <AppBar position="static" color="transparent" className={classes.appBar}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar className={clsx(classes.toolbar, userUnauthed && classes.titleUnauthed)}>
          <Typography variant="h6" className={classes.title}>
            <Link to="/" color="inherit">
              <img alt="logo" src={logo} className={classes.logo} />
            </Link>
          </Typography>
          {userAuthed && <Routes />}
          {userAuthed ? <Authed user={user} /> : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const routes = [
  ["Affiliates", "/affiliates"],
  ["Partners", "/partners"],
  ["Customers", "/customers"],
  ["Payments", "/payments"],
  ["Payouts", "/payouts"],
  ["Invoices", "/invoices"],
  ["BoomSplit", "/credit-account-enrollments"],
  ["BoomReport", "/reporting-account-enrollments"],
  ["Furnishments", "/furnishments"],
  ["Verifications", "/enrollment-verifications"],
  ["Payment Verification", "/payment-verifications"],
  ["KYB Verification", "/kyb-verifications"],
  ["Applications", "/applications"],
  ["Chats", "/chats"],
];
export const navRoutes = routes;

function Routes() {
  return (
    <>
      {routes.map(([title, href]) => (
        <MenuItem key={title}>
          <Typography>
            <Link to={href}>{title}</Link>
          </Typography>
        </MenuItem>
      ))}
    </>
  );
}

function _Authed({ user }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const close = _.bind(setAnchorEl, null);

  function handleSignOut() {
    close();
    signOut();
  }

  return (
    <div>
      <Button color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
        {user && user.firstName}
        <AccountCircle className={classes.userIcon} />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        <MenuItem
          className={classes.commitRef}
          onClick={() =>
            window.open(
              `https://github.com/boompay/boompay-admin-web/commit/${config.commitRef}`
            )
          }
        >
          Version: {config.commitRef}
        </MenuItem>
      </Menu>
    </div>
  );
}
const Authed = withRouter(_Authed);
