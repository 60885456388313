import React from "react";

const LoadingContext = React.createContext();
export const useLoading = () => React.useContext(LoadingContext);
export function LoadingProvider({ children }) {
  const [loading, setLoading] = React.useState(false);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
        startLoading: () => setLoading(true),
        stopLoading: () => setLoading(false),
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}
