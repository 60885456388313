import { AuthButton, AuthPage, AuthTitle } from "../components/authForms";
import { FormControl, TextField } from "@material-ui/core";
import FormError, { useError } from "../components/FormError";

import { Link } from "react-router-dom";
import React from "react";
import _ from "lodash";
import api from "../api";
import applyHocs from "../lithic/applyHocs";
import { constrained } from "../components/layouts";
import { useUser } from "../hooks/user";

function SignInPage() {
  const { setUser } = useUser();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [formMessage, setFormMessage] = useError();

  function onSubmit(e) {
    e.preventDefault();
    setFormMessage("");
    return api
      .signIn({ email, password })
      .then(api.pickData)
      .then((data) => {
        setUser(data);
      })
      .catch((error) => {
        let errorMsg;
        if (_.get(error, "response.status") === 401) {
          const msg = _.get(error, "response.data.error.message");
          if (msg === "No user with that email") {
            errorMsg = (
              <span>
                It looks like you don&rsquo;t yet have an account. You can head over to{" "}
                <Link to="/register">register</Link> and create one.
              </span>
            );
          } else if (msg === "Incorrect password") {
            errorMsg = (
              <span>
                Sorry, that password is incorrect. Do you need to{" "}
                <Link to="/forgot-password">reset your password</Link>?
              </span>
            );
          }
        }
        setFormMessage(errorMsg || error);
      });
  }

  return (
    <AuthPage>
      <AuthTitle>Boom admin</AuthTitle>
      <form noValidate onSubmit={onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <TextField
            label="Email Address"
            required
            type="email"
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            label="Password"
            required
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <FormError error={formMessage} resetError={setFormMessage} />
        <AuthButton>Sign In</AuthButton>
      </form>
    </AuthPage>
  );
}

export default applyHocs(constrained("sm"), SignInPage);
