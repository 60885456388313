import apiBase from "./lithic/apiBase";
import config from "./config";

const instance = apiBase.create(config.apiHost, {
  debug: config.debug,
  chaos: config.chaos,
});

const get = (path, params, opts) => {
  return instance.get(path, apiBase.mergeParams(params, opts));
};
const post = (path, params, opts) => {
  return instance.post(path, params, opts);
};
const patch = (path, params, opts) => {
  return instance.patch(path, params, opts);
};

const put = (path, params, opts) => {
  return instance.put(path, params, opts);
};

const del = (path, params, opts) => {
  return instance.delete(path, apiBase.mergeParams(params, opts));
};

function followRedirect(history) {
  return function (resp) {
    if (resp.headers["created-resource-admin"]) {
      history.push(resp.headers["created-resource-admin"]);
    }
    return resp;
  };
}

const api = {
  followRedirect,
  get,
  post,
  patch,
  put,
  delete: del,
  signOut: () => del("/v1/auth"),
  signIn: (data) => post("/v1/auth", data),

  getCurrentUser: (data) => get("/admin/v1/auth", data),
  getCustomers: (data) => get("/admin/v1/customers", data),
  getAffiliates: (data) => get("/admin/v1/affiliates", data),
  getAffiliate: (data) => get(`/admin/v1/affiliates/${data.id}`, data),
  getRentalVerifications: (data) =>
    get(`/admin/v1/reporting_account_enrollments/${data.id}/payment_verification`, data),
  getPaymentAnalyzerData: (data) =>
    get(`/admin/v1/reporting_account_enrollments/${data.id}/payment_analyzer`, data),
  getBankTransactions: (data) =>
    get(`/admin/v1/reporting_account_enrollments/${data.id}/bank_transactions`, data),
  getCustomer: (data) => get(`/admin/v1/customers/${data.id}`, data),
  getCustomerPayments: (data) => get(`/admin/v1/customers/${data.id}/payments`, data),
  getCustomerInvoices: (data) => get(`/admin/v1/customers/${data.id}/invoices`, data),
  getCustomerDocuments: (data) => get(`/admin/v1/customers/${data.id}/documents`),
  getCustomerPaymentInstruments: (data) =>
    get(`/admin/v1/customers/${data.id}/payment_instruments`, data),
  getCustomerBankAccounts: (data) =>
    get(`/admin/v1/customers/${data.id}/bank_accounts`, data),
  updateCustomer: (data) => post(`/admin/v1/customers/${data.id}`, data),
  updateAffiliate: (data) => patch(`/admin/v1/affiliates/${data.id}`, data),
  createAffiliate: (data) => post("/admin/v1/affiliates", data),
  removeAffiliate: (data) => del(`/admin/v1/affiliates/${data.id}`, data),
  closeCustomerAccount: (data) => post(`/admin/v1/customers/${data.id}/close`, data),
  markFraudulent: (data) => post(`/admin/v1/customers/${data.id}/mark_fraudulent`, data),
  unmarkFraudulent: (data) =>
    post(`/admin/v1/customers/${data.id}/unmark_fraudulent`, data),

  addCustomerReferral: (data) =>
    post(`/admin/v1/customers/${data.id}/add_referral`, data),

  getAccountBalances: (data) =>
    get(`/admin/v1/customers/${data.id}/account_balances`, data),

  listPayments: (data) => post("/admin/v1/payments/list", data),
  buildDownloadPaymentsUrl: (data) => post("/admin/v1/payments/list/download.csv", data),
  getPayment: (data) => get(`/admin/v1/payments/${data.id}`, data),
  updatePayment: (data) => patch(`/admin/v1/payments/${data.id}`, data),

  reviewPayment: (data) => post(`/admin/v1/payments/${data.id}/review`, data),
  unreviewPayment: (data) => del(`/admin/v1/payments/${data.id}/review`, data),
  markPaymentStrategyFundsSent: (data) =>
    post(`/admin/v1/payments/${data.id}/strategy/mark_funds_sent`, data),

  listInvoices: (data) => post("/admin/v1/invoices/list", data),
  getInvoice: (data) => get(`/admin/v1/invoices/${data.id}`, data),
  updateInvoice: (data) => patch(`/admin/v1/invoices/${data.id}`, data),

  listPayouts: (data) => post("/admin/v1/payouts/list", data),
  getPayout: (data) => get(`/admin/v1/payouts/${data.id}`, data),
  updatePayout: (data) => patch(`/admin/v1/payouts/${data.id}`, data),

  updateEnrollmentCasePortal: (data) =>
    patch(`/admin/v1/enrollment_cases/${data.id}/portal`, data),
  updateEnrollmentCaseLease: (data) =>
    patch(`/admin/v1/enrollment_cases/${data.id}/lease`, data),
  updateEnrollmentCaseLandlord: (data) =>
    patch(`/admin/v1/enrollment_cases/${data.id}/landlord`, data),
  closeEnrollmentCase: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/close`, data),

  verifyEnrollmentCaseLandlord: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/verify_landlord_verification`, data),
  rejectEnrollmentCaseLandlord: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/reject_landlord_verification`, data),
  verifyEnrollmentCaseLease: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/verify_lease_verification`, data),
  rejectEnrollmentCaseLease: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/reject_lease_verification`, data),
  verifyUploadedLease: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/verify_uploaded_lease`, data),
  rejectUploadedLease: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/reject_uploaded_lease`, data),
  verifyEnrollmentCasePortal: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/verify_portal_verification`, data),
  rejectEnrollmentCasePortal: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/reject_portal_verification`, data),
  verifyEnrollmentCaseIdentity: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/verify_identity_verification`, data),
  rejectEnrollmentCaseIdentity: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/reject_identity_verification`, data),

  listReportingAccountEnrollments: (data) =>
    post("/admin/v1/reporting_account_enrollments/list", data),
  exportReportingAccountEnrollments: (data) =>
    post("/admin/v1/reporting_account_enrollments/list/export_to_csv", data, {
      responseType: "blob",
    }),
  getReportingAccountEnrollment: (data) =>
    get(`/admin/v1/reporting_account_enrollments/${data.id}`),
  updateReportingAccountEnrollment: (data) =>
    patch(`/admin/v1/reporting_account_enrollments/${data.id}`, data),
  closeReportingAccountEnrollment: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/close`, data),
  closeTradelineReportingAccountEnrollment: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/close_tradeline`, data),
  deleteTradelineReportingAccountEnrollment: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/delete_tradeline`, data),
  approveReportingAccountEnrollment: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/approve`, data),
  rejectReportingAccountEnrollment: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/reject`, data),
  reconsiderReportingAccountEnrollment: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/reconsider`, data),
  unenrollOngoing: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/unenroll_ongoing`, data),

  getReportingAccountEnrollmentRentalVerifications: (data) =>
    get(`/admin/v1/reporting_account_enrollments/${data.id}/rental_verifications`),
  createReportingAccountEnrollmentRentalVerification: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/rental_verifications`, data),
  getMetro2Files: (data) =>
    get(`/admin/v1/reporting_account_enrollments/${data.id}/metro2_files`, data),
  listRentalVerifications: (data) =>
    post("/admin/v1/enrollment_verifications/list", data),
  getRentalVerification: (data) => get(`/admin/v1/rental_verifications/${data.id}`),
  updateRentalVerification: (data) =>
    patch(`/admin/v1/rental_verifications/${data.id}`, data),
  verifyRentPayment: (data) =>
    post(`/admin/v1/rental_verifications/${data.id}/approve`, data),
  rejectRentPayment: (data) =>
    post(`/admin/v1/rental_verifications/${data.id}/reject`, data),
  linkTransaction: ({ id }, payload = {}) =>
    post(`/admin/v1/rental_verifications/${id}/link_transaction`, payload),
  unlinkTransaction: ({ id }, payload = {}) =>
    post(`/admin/v1/rental_verifications/${id}/unlink_transaction`, payload),
  removeTransactionSource: (data) =>
    post(`/admin/v1/rental_verifications/${data.id}/remove_source`, data),
  approveRentalVerifications: (data) =>
    post("/admin/v1/rental_verifications/bulk_approve", data),
  rejectRentalVerifications: (data) =>
    post("/admin/v1/rental_verifications/bulk_reject", data),
  listCreditAccountEnrollments: (data) =>
    post("/admin/v1/credit_account_enrollments/list", data),
  getCreditAccountEnrollment: (data) =>
    get(`/admin/v1/credit_account_enrollments/${data.id}`, data),
  updateCreditAccountEnrollment: (data) =>
    patch(`/admin/v1/credit_account_enrollments/${data.id}`, data),
  approveCreditAccountEnrollment: (data) =>
    post(`/admin/v1/credit_account_enrollments/${data.id}/approve`, data),
  rejectCreditAccountEnrollment: (data) =>
    post(`/admin/v1/credit_account_enrollments/${data.id}/reject`, data),
  reconsiderCreditAccountEnrollment: (data) =>
    post(`/admin/v1/credit_account_enrollments/${data.id}/reconsider`, data),
  scheduleCreditAccountPaymentDebit: (data) =>
    post(`/admin/v1/credit_account_enrollments/${data.id}/schedule_payment_debit`, data),
  scheduleCreditAccountRepaymentCredit: (data) =>
    post(
      `/admin/v1/credit_account_enrollments/${data.id}/schedule_repayment_credit`,
      data
    ),
  switchCreditAccountEnrollmentBankAccount: (data) =>
    post(`/admin/v1/credit_account_enrollments/${data.id}/switch_bank_account`, data),

  getBankAccount: (data) => get(`/admin/v1/bank_accounts/${data.id}`, data),
  getCard: (data) => get(`/admin/v1/cards/${data.id}`, data),
  createPresignedDocumentUrl: (data) =>
    post(
      `/admin/v1/customers/${data.customerId}/documents/presign_put_url?filename=${data.filename}`
    ),
  createDocument: (data) =>
    post(`/admin/v1/customers/${data.customerId}/documents/`, data),

  getPartners: (data) => get("/admin/v1/partners", data),
  getPartner: (data) => get(`/admin/v1/partners/${data.id}`, data),
  createPartner: (data) => post(`/admin/v1/partners/`, data),
  getPartnerInvoices: (data) => get(`/admin/v1/partners/${data.id}/invoices`, data),
  getPartnerPayouts: (data) => get(`/admin/v1/partners/${data.id}/payouts`, data),
  getPartnerStatements: (data) => get(`/admin/v1/partners/${data.id}/statements`, data),
  getPartnerUpcomingStatements: (data) =>
    get(`/admin/v1/partners/${data.id}/statements/upcoming`, data),
  getPartnerStatement: (data) =>
    get(`/admin/v1/partners/${data.id}/statements/${data.statement_id}`, data),
  getPartnerStatementActivities: (data) =>
    get(`/admin/v1/partners/${data.id}/statements/${data.statement_id}/activities`, data),
  getPartnerPaymentInstruments: (data) =>
    get(`/admin/v1/partners/${data.id}/payment_instruments`, data),
  getPartnerUnits: (data) => get(`/admin/v1/partners/${data.id}/units`, data),
  updatePartner: (data) => patch(`/admin/v1/partners/${data.id}`, data),
  createPricingTier: (data) =>
    post(`/admin/v1/partners/${data.partner_id}/pricing_tiers`, data),
  updatePricingTier: (data) =>
    patch(`/admin/v1/partners/${data.partner_id}/pricing_tiers/${data.tier_id}`, data),
  createVerificationPrices: (data) =>
    post(`/admin/v1/partners/${data.partner_id}/verification_prices`, data),
  updateVerificationPrices: (data) =>
    patch(
      `/admin/v1/partners/${data.partner_id}/verification_prices/${data.price_id}`,
      data
    ),

  // Furnishments
  listFurnishments: (data) => get("/admin/v1/furnishments", data),

  getFurnishment: (data) => get(`/admin/v1/furnishments/${data.id}`),

  getReportingAccountEnrollmentsByFurnishment: (data) =>
    post(
      `/admin/v1/furnishments/${data.furnishment_id}/reporting_account_enrollments`,
      data
    ),

  createReportingAccountEnrollmentRefurnishment: (data) =>
    post(`/admin/v1/reporting_account_enrollments/${data.id}/refurnishments`, data),

  addDocumentForReportingAccountEnrollment: (data) =>
    post(`/admin/v1/enrollment_cases/${data.id}/add_document`, data),

  getReportingAccountEnrollmentFurnishments: (data) =>
    get(`/admin/v1/reporting_account_enrollments/${data.id}/furnishments`),

  getCustomerSubscriptions: (data) =>
    get(`/admin/v1/customers/${data.customerId}/subscriptions`),

  updateCustomerSubscription: (data) =>
    patch(`/admin/v1/customers/${data.customerId}/subscriptions/${data.subscriptionId}`, {
      autoRenew: data.autoRenew,
    }),

  renewCustomerSubscription: (data) =>
    post(`/admin/v1/customers/${data.customerId}/subscriptions/renew`, {
      months: data.renewalPeriod,
    }),

  cancelCustomerSubscription: (data) =>
    post(
      `/admin/v1/customers/${data.customerId}/subscriptions/${data.subscriptionId}/cancel`
    ),

  refundLineItems: (data) => post(`/admin/v1/invoices/${data.invoiceId}/refund`, data),
  getAuditLogs: (auditable_id, auditable_type) =>
    get(`/admin/v1/audit_logs`, { auditable_id, auditable_type }),
  getEnrollmentVerification: (id) => get(`/admin/v1/enrollment_verifications/${id}`),
  listEnrollmentVerificationsForEnrollment: (enrollmentId) =>
    get(`/admin/v1/reporting_account_enrollments/${enrollmentId}/verifications`),

  getFilesForEnrollment: (id) =>
    get(`/admin/v1/reporting_account_enrollments/${id}/files`),
  postUpdateCheck: (enrollmentVerificationId, check) =>
    post(`/admin/v1/enrollment_verifications/${enrollmentVerificationId}/update_check`, {
      check,
    }),
  postApproveEnrollmentVerification: (id, memo) =>
    post(`/admin/v1/enrollment_verifications/${id}/approve`, { memo }),
  postRejectEnrollmentVerification: (id, memo) =>
    post(`/admin/v1/enrollment_verifications/${id}/reject`, { memo }),
  restartEnrollmentVerification: (id) =>
    post(`/admin/v1/enrollment_verifications/${id}/restart`),
  listEnrollmentsReadForPaymentVerification: (data) =>
    post("/admin/v1/reporting_account_enrollments/ready_for_payment_verification", data),

  uploadReportsToBureau: (data) =>
    post(
      `/admin/v1/furnishments/${data.id}/reports/${data.reportId}/upload_to_bureau`,
      data
    ),

  generateFurnishment: (data) =>
    post(`/admin/v1/furnishments/generate_furnishment`, data),

  getTradlinesForEnrollment: (enrollmentId) =>
    get(`/admin/v1/tradelines`, { enrollmentId }),
  getTradeline: (tradelineId) => get(`/admin/v1/tradelines/${tradelineId}`),
  syncBureauStatusForTradeline: (tradelineId) =>
    post(`/admin/v1/tradelines/${tradelineId}/sync`),
  updateTradeline: (data) => patch(`/admin/v1/tradelines/${data.tradelineId}`, data),

  getFeedTimeLine: (userId, payload) =>
    get(
      `/admin/v1/reporting_account_enrollments/${userId}/furnishments_timeline`,
      payload
    ),

  listKybVerifications: (data) => post("/admin/v1/kyb_verifications/list", data),
  getKybVerification: (id) => get(`/admin/v1/kyb_verifications/${id}`),
  postKybVerificationUpdateCheck: (kybVerificationId, check) =>
    post(`/admin/v1/kyb_verifications/${kybVerificationId}/update_check`, {
      check,
    }),
  postApproveKybVerification: (id, memo) =>
    post(`/admin/v1/kyb_verifications/${id}/approve`, { memo }),
  postRejectKybVerification: (id, memo) =>
    post(`/admin/v1/kyb_verifications/${id}/reject`, { memo }),

  listIdentityVerifications: (data) => post(`/admin/v1/identity_verifications`, data),

  getVerificationSchemas: (data) => get(`/admin/v1/verification_schemas`, data),

  listApplications: (data) => post(`/admin/v1/applications`, data),
  getApplication: (id) => get(`/admin/v1/applications/${id}`),
  getApplicant: (id) => get(`/admin/v1/applicants/${id}`),
  getBoomScreenVerification: (id) => get(`/admin/v1/verifications/${id}`),
  presignAsset: (queryParams) => get(`/admin/v1/assets/presign?${queryParams}`),
  createAsset: (data) => post(`/admin/v1/assets`, data),
  setPartnerLogo: (partnerId, data) =>
    post(`/admin/v1/partners/${partnerId}/set_logo`, data),
  impersonatePartnerUser: (partnerId, user) =>
    post(`/admin/v1/partners/${partnerId}/impersonate`, { partner_user_id: user.id }),
  bypassVerifiedIncomeVerification: ({ verificationId }) =>
    post(`/admin/v1/verifications/${verificationId}/skip`),
  resubmitApplicant: (applicantId) =>
    post(`/admin/v1/applicants/${applicantId}/resubmit`),
  restartApplicant: (applicantId) => post(`/admin/v1/applicants/${applicantId}/restart`),
  resubmitApplication: (applicationId) =>
    post(`/admin/v1/applications/${applicationId}/resubmit`),
  restartApplication: (applicationId) =>
    post(`/admin/v1/applications/${applicationId}/restart`),
  changeApplicationUnit: (applicationId, data) =>
    patch(`/admin/v1/applications/${applicationId}/unit`, data),
  patchPartnerUser: (partnerUserId, payload) =>
    patch(`/admin/v1/partner_users/${partnerUserId}`, payload),
  restartVerifiedIncomeVerification: ({ verificationId }) =>
    post(`/admin/v1/verifications/${verificationId}/restart`),
  changeApplicantRole: (applicantId, payload) =>
    patch(`/admin/v1/applicants/${applicantId}/role`, payload),
  changeApplicantName: (applicantId, data) =>
    patch(`/admin/v1/applicants/${applicantId}/name`, data),
  getReport: (applicantId) => get(`/admin/v1/applicants/${applicantId}/report`),
};

export default { ...apiBase, ...api, instance };
