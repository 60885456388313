import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { AdminContainer } from "../../components/layouts";
import { Typography, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../api";
import { useLoading } from "../../hooks/loading";
import DetailSection from "../../components/DetailSection";
import AdminActionLinks from "../../components/AdminActionLinks";
import { ApplicantVerifications } from "./ApplicantVerifications";
import Time from "../../lithic/Moment";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import SimpleTable from "../../components/SimpleTable";
import Link from "../../components/Link";
import Money from "../../lithic/Money";
import { AuditLogs } from "../../components/AuditLogs";
import InlineEditable from "../../components/InlineEditable";
import { GutterlessListItem as ListItem } from "../../components/lists";

const warningText =
  "***Changing role resulted in resetting the current applicant's application data";

export default function ApplicantDetailPage() {
  const iframeRef = useRef(null);
  const classes = useStyles();
  const { id } = useParams();

  const { startLoading, stopLoading } = useLoading();

  const [applicant, setApplicant] = useState(null);
  const [printLoading, setPrintLoading] = useState(false);

  const refetchApplicant = () => {
    startLoading();
    api
      .getApplicant(id)
      .then((resp) => setApplicant(resp.data))
      .catch((err) => toast.error(err.message))
      .finally(stopLoading);
  };
  useEffect(() => {
    refetchApplicant();
  }, [id]);

  if (!applicant) return "Loading...";

  return (
    <AdminContainer variant="list">
      <iframe ref={iframeRef} style={{ display: "none" }} title="PDF Print" />
      <div className={classes.applicantDetailsContainer}>
        <Typography variant="h4">Applicant Details</Typography>
        <Paper className={classes.detailPaper}>
          <Typography variant="h6">
            Name: {applicant.firstName} {applicant.lastName}
          </Typography>
          <Typography variant="body1">Email: {applicant.email}</Typography>
          <Typography variant="body1">Role: {applicant.role}</Typography>
          <Typography variant="body1">
            Application Status: {applicant.applicationStatus}
          </Typography>
          <Typography variant="body1">
            Created At:{" "}
            <Time key={applicant.id} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
              {applicant.createdAt}
            </Time>
          </Typography>
          <Typography variant="body1">
            Updated At:{" "}
            <Time key={applicant.id} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
              {applicant.updatedAt}
            </Time>
          </Typography>

          {/* Intercom link */}
          <SafeExternalLink href={applicant.intercomLink}>
            {applicant.intercomLink ? "Intercom link" : "--"}
          </SafeExternalLink>

          {/* PostHog recordings */}
          <div>
            <SafeExternalLink
              href={`https://us.posthog.com/project/67848/replay/home?filters=%7B%22filter_test_accounts%22%3Afalse%2C%22date_from%22%3A%22-90d%22%2C%22date_to%22%3Anull%2C%22filter_group%22%3A%7B%22type%22%3A%22AND%22%2C%22values%22%3A%5B%7B%22type%22%3A%22AND%22%2C%22values%22%3A%5B%7B%22key%22%3A%22customer_email%22%2C%22value%22%3A%5B%22${encodeURIComponent(
                applicant.email
              )}%22%5D%2C%22operator%22%3A%22exact%22%2C%22type%22%3A%22person%22%7D%5D%7D%5D%7D%2C%22duration%22%3A%5B%7B%22type%22%3A%22recording%22%2C%22key%22%3A%22duration%22%2C%22value%22%3A1%2C%22operator%22%3A%22gt%22%7D%5D%7D`}
            >
              {applicant.email ? "PostHog recordings" : "--"}
            </SafeExternalLink>
          </div>
        </Paper>

        <DetailSection title="Edit Role">
          <InlineEditable
            label="Role"
            value={applicant.role}
            ListItemComponent={ListItem}
            onCommit={(newRole) => {
              api
                .changeApplicantRole(applicant.id, { role: newRole })
                .then(() => {
                  toast.success(`Applicant updated successfully!`);
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            }}
            warning={true}
            warningComponent={<h2>{warningText}</h2>}
            inputType="select"
            inputProps={{
              options: [
                { value: "applicant", label: "Applicant" },
                { value: "co_signer", label: "Co Signer" },
              ],
            }}
          />
        </DetailSection>

        {["submitted", "approved", "approved_with_conditions", "rejected"].includes(
          applicant.applicationStatus
        ) ? (
          <DetailSection title="Report">
            <Button
              variant="contained"
              color="primary"
              disabled={printLoading}
              onClick={async () => {
                try {
                  setPrintLoading(true);
                  const result = await api.getReport(id);
                  const response = await fetch(result.data.url);
                  if (!response.ok) throw new Error("Failed to fetch PDF");

                  const blob = await response.blob();
                  const blobUrl = window.URL.createObjectURL(
                    new Blob([blob], { type: "application/pdf" })
                  );

                  if (iframeRef.current) {
                    iframeRef.current.src = blobUrl;

                    iframeRef.current.onload = () => {
                      iframeRef.current.contentWindow.focus();
                      iframeRef.current.contentWindow.print();
                    };
                  }
                } catch (err) {
                  toast.error(err.message);
                } finally {
                  setPrintLoading(false);
                }
              }}
            >
              {printLoading ? "Loading..." : "PDF Viewer"}
            </Button>
          </DetailSection>
        ) : null}

        <DetailSection title="Verifications">
          <ApplicantVerifications
            verifications={applicant.verifications}
            refetch={refetchApplicant}
          />
        </DetailSection>
        <AdminActionLinks
          links={applicant.adminActionLinks}
          onActionSuccess={(resp) => setApplicant(resp.data)}
        />
        {/* Temporarily. Waiting for the backend update */}
        <DetailSection>
          <Typography variant="h6" gutterBottom color="primary">
            Portal
          </Typography>
          <SafeExternalLink href="/">Open Portal</SafeExternalLink>
        </DetailSection>
        <DetailSection title="Invoices">
          <SimpleTable
            truncated
            tableProps={{ size: "small" }}
            headers={["ID", "Status", "Amount", "Updated at"]}
            rows={applicant.invoices}
            keyRowAttr="id"
            toCells={(row) => [
              <Link key={1} to={`/invoices/${row.id}`}>
                {row.id}
              </Link>,
              <Link key={4} to={`/invoices/${row.id}`}>
                {row.status}
              </Link>,
              <Money key={2} value={row.total} />,
              <Time key={5} format={"MMM DD, YYYY, h:mm:ss a"}>
                {row.updatedAt}
              </Time>,
            ]}
          />
        </DetailSection>
        <DetailSection title="Audit Logs">
          <AuditLogs auditableId={applicant.id} auditableType="BoomScreen::Applicant" />
        </DetailSection>
      </div>
    </AdminContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  applicantDetailsContainer: {
    flex: 1,
    padding: theme.spacing(2),
  },
  detailPaper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));
