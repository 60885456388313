import { AdminContainer, AlignedListContainer } from "../components/layouts";
import Time, { Period, isoString } from "../lithic/Moment";

import AdminActionLinks from "../components/AdminActionLinks";
import AuditLogsTable from "../components/AuditLogsTable";
import Box from "@material-ui/core/Box";
import ExternalLinksList from "../components/ExternalLinksList";
import InlineEditable from "../components/InlineEditable";
import LegalEntityListItems from "../components/LegalEntityListItems";
import Link from "../components/Link";
import { GutterlessListItem as ListItem } from "../components/lists";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../lithic/Money";
import PaymentInstrumentListItems from "../components/PaymentInstrumentListItems";
import { Button, Radio } from "@material-ui/core";
import React from "react";
import SelectInput from "../components/SelectInput";
import TextField from "@material-ui/core/TextField";
import api from "../api";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import LineItemBreakDownTable from "../components/LineItemBreakDownTable";
import DetailSection from "../components/DetailSection";

const useStyles = makeStyles((theme) => ({
  inactive: {
    color: theme.palette.muted.main,
  },
  pmRoot: {
    display: "flex",
    flex: 1,
  },
  pmRadios: {
    display: "flex",
    flexDirection: "column",
  },
  pmInputs: {
    display: "flex",
    flexDirection: "column",
  },
  pmInputLast: {
    marginTop: theme.spacing(1),
  },
}));

export default function PayoutDetailPage() {
  const { id } = useParams();
  const classes = useStyles();

  const [payout, setPayout] = React.useState({});
  const [lineItemsShown, setLineItemsShown] = React.useState({});
  React.useEffect(() => {
    api.getPayout({ id }).then(api.pickData).then(setPayout);
  }, [id]);

  function saveAttribute(params) {
    return api
      .updatePayout({ id, ...params })
      .then(api.pickData)
      .then(setPayout);
  }

  const showLineItemBreakdown = (lineItemId) => {
    setLineItemsShown({
      ...lineItemsShown,
      [lineItemId]: !lineItemsShown[lineItemId],
    });
  };

  return (
    <AdminContainer variant="detail" customer={payout.customer}>
      <AlignedListContainer maxWidth="sm">
        <ListItem>
          <ListItemText>Payout Id: {payout.id}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Created:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {payout.createdAt}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className={clsx(!payout.updatedAt && classes.inactive)}>
            Updated:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {payout.updatedAt}
            </Time>
          </ListItemText>
        </ListItem>
        {payout.affiliate && (
          <ListItem>
            <ListItemText>
              Affiliate:{" "}
              <Link to={`/affiliates/${payout.affiliate.id}`}>
                {payout.affiliate.affiliateCode} ({payout.affiliate.id})
              </Link>
            </ListItemText>
          </ListItem>
        )}
        <LegalEntityListItems legalEntity={payout?.legalEntity} />
        <InlineEditable
          label="Period"
          value={payout.period}
          displayValue={
            <Period d="(unset)" format={"MMM DD, YYYY HH:mm:ss"} value={payout.period} />
          }
          inputType="datetimerange"
          inputProps={{ ampm: false }}
          ListItemComponent={ListItem}
          onCommit={({ start, end }) =>
            saveAttribute({
              periodBegin: isoString(start),
              periodEnd: isoString(end),
            })
          }
          editable={payout.editable}
        />
        <ListItem>
          <ListItemText>Status: {payout.status}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Total: <Money value={payout.total} />
          </ListItemText>
        </ListItem>
        <PaymentInfo payout={payout} onCommit={saveAttribute} />
        <DetailSection title="Line Items">
          <ListItem className={clsx(!payout.lineItems?.length && classes.inactive)}>
            <ListItemText>Total Line Items: {payout.lineItems?.length}</ListItemText>
          </ListItem>
          {payout.lineItems?.map(({ id, description, amount, adminLink, metadata }) => (
            <>
              <ListItem key={id}>
                <ListItemText>
                  {description} - <Money value={amount} /> (Id: {id}){" "}
                  {adminLink.url ? (
                    <Link to={adminLink.url}>{adminLink.title}</Link>
                  ) : (
                    adminLink.title
                  )}
                </ListItemText>
                <Button onClick={() => showLineItemBreakdown(id)}> Details </Button>
              </ListItem>
              <LineItemBreakDownTable
                key={id}
                items={metadata.items}
                shown={lineItemsShown[id]}
              />
            </>
          ))}
        </DetailSection>
        <ExternalLinksList links={payout.externalLinks} />
        <AdminActionLinks
          links={payout.adminActionLinks}
          onActionSuccess={(resp) => setPayout(resp.data)}
        />
      </AlignedListContainer>
      <Box mt={3}>
        <AuditLogsTable auditLogs={payout.auditLogs} />
      </Box>
    </AdminContainer>
  );
}

function PaymentInfo({ payout, onCommit }) {
  const editable = payout.status === "processed" || payout.editable;
  if (!payout) {
    return null;
  }
  if (!editable) {
    if (payout.externalSettlementAccount) {
      return (
        <ListItem>
          <ListItemText>
            External Settlement: {payout.externalSettlementAccount}
          </ListItemText>
        </ListItem>
      );
    }
    if (payout.bankAccount) {
      return (
        <>
          <ListItem>
            <ListItemText>Bank Account:</ListItemText>
          </ListItem>
          <PaymentInstrumentListItems instrument={payout.bankAccount} />
        </>
      );
    }
    return (
      <ListItem>
        <ListItemText>Payment Method: (not set)</ListItemText>
      </ListItem>
    );
  }

  let displayValue;
  if (payout.externalSettlementAccount) {
    displayValue = "External: " + payout.externalSettlementAccount;
  } else if (payout.bankAccount) {
    displayValue = "Bank: " + payout.bankAccount.label;
  } else {
    displayValue = "(not set)";
  }

  function handleCommit({ method, bankAccountId, externalSettlementAccount }) {
    if (method === "bank") {
      onCommit({ bankAccountId });
    } else {
      onCommit({ externalSettlementAccount });
    }
  }
  return (
    <InlineEditable
      label="Payment Method"
      displayValue={displayValue}
      value={{
        bankAccountId: payout.bankAccount?.id,
        externalSettlementAccount: payout.externalSettlementAccount || "",
      }}
      editable={editable}
      inputProps={{ bankAccounts: payout.availableBankAccounts }}
      ListItemComponent={ListItem}
      InputComponent={PaymentMethodControls}
      onCommit={handleCommit}
    />
  );
}

function PaymentMethodControls({ className, value, onChangeValue, bankAccounts }) {
  const classes = useStyles();
  const { bankAccountId, externalSettlementAccount } = value;
  const [activeMethod, setActiveMethod] = React.useState(
    externalSettlementAccount ? "external" : "bank"
  );
  function handleValueChange(o) {
    onChangeValue({ ...value, ...o, method: activeMethod });
  }
  return (
    <div className={classes.pmRoot}>
      <div className={classes.pmRadios}>
        <Radio
          checked={activeMethod === "bank"}
          onChange={() => setActiveMethod("bank")}
          value="bank"
          name="radio-buttons"
        />
        <Radio
          checked={activeMethod === "external"}
          onChange={() => setActiveMethod("external")}
          value="external"
          name="radio-buttons"
        />
      </div>
      <div className={clsx(classes.pmInputs, className)}>
        <SelectInput
          label="Bank Account"
          value={bankAccountId}
          options={(bankAccounts || []).map((ba) => ({
            label: ba.label,
            value: ba.id,
          }))}
          disabled={activeMethod !== "bank"}
          onChangeValue={(bankAccountId) => handleValueChange({ bankAccountId })}
        />
        <TextField
          label="External Settlement"
          className={classes.pmInputLast}
          value={externalSettlementAccount}
          disabled={activeMethod !== "external"}
          onChange={(ev) =>
            handleValueChange({ externalSettlementAccount: ev.target.value })
          }
        />
      </div>
    </div>
  );
}
